import React from 'react';
import Spacer from '../shared/Spacer';
import * as Styled from './style';

const DEFAULT_DESC_VALUE = 'MISSING_TRANSLATION';
interface IAchievementDetailModal {
  title?: string;
  description?: string;
  icon?: string;
  onClose: () => void;
}

const AchievementDetailModal: React.FC<IAchievementDetailModal> = ({
  title,
  description,
  icon,
  onClose
}) => {
  return (
    <Styled.Container>
      <Spacer spacing={3} />
      <Styled.ContainerInner>
        <Styled.Icon src={icon} />
        <Spacer spacing={3} />
        <Styled.Title>{title}</Styled.Title>
        {description !== DEFAULT_DESC_VALUE && (
          <>
            <Spacer spacing={3} />
            <p>{description}</p>
          </>
        )}
      </Styled.ContainerInner>
      <Styled.Button onClick={onClose}>Close</Styled.Button>
    </Styled.Container>
  );
};

export default AchievementDetailModal;
