import styled from 'styled-components';
import ThemeButton from '../shared/Button';
import Breakpoints from '../../services/breakpoints';

export const Container = styled.div`
  width: 30%;
  height: 50%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #000;
  text-align: center;
  @media screen and (max-width: ${Breakpoints.MOBILE}) {
    height: 80%;
    width: 80%;
  }
`;

export const ContainerInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

export const Icon = styled.img`
  height: 80px;
  width: 80px;
  object-fit: contain;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
`;

export const Button = styled(ThemeButton)`
  width: 70%;
`;
