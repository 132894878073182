import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import PageHeader from '../components/shared/PageHeader';
import Container from '../components/shared/Container';
import Spacer from '../components/shared/Spacer';
import Modal from '../components/shared/Modal';
import AchievementItem from '../components/AchievementItem';
import AchievementDetailModal from '../components/AchievementDetailModal';
import { AchievementData } from '@insertcoinab/gwen-react-components/dist/types';
import { ErrorContext } from '../contexts/ErrorContext';
import { Endpoints, get } from '../api';
import bpIcon from '../images/backpack.png';

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  text-align: center;
  box-sizing: border-box;
`;

const Title = styled.h1`
  font-size: 1.3rem;
`;

const ContainerAchievements = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: auto;
  justify-items: center;
  overflow-y: auto;
`;
const ContainerAchievementItem = styled.div`
  margin: 1rem;
`;

const Backpack = () => {
  const [currentAchievement, setCurrentAchievement] = useState<AchievementData>(
    null
  );
  const [achievements, setAchievements] = useState<AchievementData[]>();
  const { handleError } = useContext(ErrorContext);

  const getAchievements = async () => {
    const data = await get(Endpoints.ACHIEVEMENT);
    if (!!data) {
      setAchievements(data);
    } else {
      handleError('Could not get achievements...');
    }
  };

  useEffect(() => {
    getAchievements();
  }, []);

  const handleAchievementSelected = (achievement: AchievementData) => {
    setCurrentAchievement(achievement);
  };

  const closeModal = () => {
    setCurrentAchievement(null);
  };

  const itemsFound = useMemo(() => {
    if (achievements?.length > 0) {
      return achievements.reduce(
        (tot, achievement) => tot + (achievement.completed ? 1 : 0),
        0
      );
    }
    return 0;
  }, [achievements]);

  return (
    <>
      <StyledContainer>
        <PageHeader title="Backpack" iconRight={bpIcon} />
        <Title>ITEMS FOUND</Title>
        <p>{`${itemsFound} / ${achievements?.length ?? 0}`}</p>
        <Spacer spacing={3} />
        <ContainerAchievements>
          {achievements?.map((achievement) => (
            <ContainerAchievementItem>
              <AchievementItem
                key={achievement.id}
                onClick={() => handleAchievementSelected(achievement)}
                icon={achievement.icon}
                disabled={!achievement.completed}
              />
            </ContainerAchievementItem>
          ))}
        </ContainerAchievements>
      </StyledContainer>
      <Modal open={!!currentAchievement} onClose={closeModal}>
        <AchievementDetailModal
          icon={currentAchievement?.icon}
          title={currentAchievement?.title}
          description={currentAchievement?.description}
          onClose={closeModal}
        />
      </Modal>
    </>
  );
};

export default Backpack;
