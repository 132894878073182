import React from 'react';
import styled, { css, keyframes } from 'styled-components';

interface IModal {
  open: boolean;
  onClose: () => void;
}

const fadein = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const StyledModal = styled.div<{ open: boolean }>`
  background-color: rgba(4, 51, 255, 0.5);
  visibility: hidden;
  position: absolute;
  padding: ${({ theme }) => theme.modal.marginTop} 16px 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  ${({ open }) =>
    open &&
    css`
      visibility: visible;
      animation: ${fadein} 0.5s;
      -webkit-animation: ${fadein} 0.5s;
    `}
`;

const Modal: React.FC<IModal> = ({ open, onClose, children }) => (
  <StyledModal open={open} onClick={onClose}>
    {children}
  </StyledModal>
);

export default Modal;
